import React from "react"

import Layout from "../components/layout"
import ServicesBusiness from "../components/marketing/services-business"
import SEO from "../components/seo"

const ServicesBusinessPage = () => (
  <Layout>
    <SEO title="Business Disposal Services" />
    <ServicesBusiness/>
  </Layout>
)

export default ServicesBusinessPage
